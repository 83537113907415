<template>
    <div>
        <el-dialog v-model="isShow" width="80%" :draggable="true" :destroy-on-close="true">
            <template #title>
                <span>{{ merchantName }} - 优惠活动管理</span>
            </template>
            <div>
                <el-radio-group v-model="activity" size="large" @change="change">
                    <el-radio-button label="activity_list">活动列表</el-radio-button>
                    <el-radio-button label="requested_activity">已申请活动</el-radio-button>
                </el-radio-group>
            </div>
            <div class="list-box">
                <div v-show="showTable == 'activity_list'">
                    <c-table ref="activityList" :tableDataUrl="activityListDataUrl" :isLoadData="true" :border="true">
                        <el-table-column prop="payment_channel_name" label="渠道名称" />
                        <el-table-column prop="name" label="活动名称" />
                        <el-table-column prop="subsidies_mode" label="补贴模式" />
                        <el-table-column prop="regions" label="限制地区" />
                        <el-table-column prop="mer_account_type" label="帐户类型" />
                        <el-table-column label="状态">
                            <template #default="scope">
                                <span v-if="scope.row.status == 1"><el-tag type="info">已申请</el-tag></span>
                                <span v-if="scope.row.status == 2"><el-tag type="success">审核通过</el-tag></span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="effective_start" label="有效期起" />
                        <el-table-column prop="effective_end" label="有效期止" />
                        <el-table-column prop="activity_start" label="报名开始" />
                        <el-table-column prop="activity_end" label="报名结束" />
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-link type="primary" @click="description(scope.row)">查看详情</el-link>
                                <el-popconfirm title="确定报名吗?" @confirm="activityRegister(scope.row)">
                                    <template #reference>
                                        <el-link type="primary" style="margin-left:15px">活动报名</el-link>
                                    </template>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </c-table>
                </div>
                <div v-show="showTable == 'requested_activity'" v-loading="loading">
                    <c-table ref="requested_activity" :tableDataUrl="requestedActivityDataUrl" :isLoadData="false">
                        <el-table-column prop="payment_channel_name" label="渠道名称" />
                        <el-table-column prop="activity_name" label="活动名称" />
                        <el-table-column prop="preferred_rate_config" label="优惠费率">
                            <template #default="scope">
                                <el-tooltip class="box-item" effect="dark" :content="scope.row.preferred_rate_config"
                                    placement="top-start">
                                    <span class="text-overflow">{{ scope.row.preferred_rate_config }}</span>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="preferred_mode" label="模式" />
                        <el-table-column prop="preferred_config_mode" label="费率配置模式" />
                        <el-table-column prop="preferential_limit" label="优惠总额度" />
                        <el-table-column label="状态">
                            <template #default="scope">
                                <span v-if="scope.row.status == 1"><el-tag type="info">申请中</el-tag></span>
                                <span v-if="scope.row.status == 2"><el-tag type="success">审核通过</el-tag></span>
                                <span v-if="scope.row.status == 3"><el-tag type="success">已生效</el-tag></span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="start" label="活动开始时间" />
                        <el-table-column prop="end" label="活动结束时间" />
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-link type="primary" @click="merchantActivityStatusQuery(scope.row)">状态查询</el-link>
                                <el-popconfirm title="确定配置费率吗?" @confirm="setActivityRates(scope.row)">
                                    <template #reference>
                                        <el-link type="primary" style="margin-left:15px">费率配置</el-link>
                                    </template>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </c-table>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script setup>
import { defineExpose, ref, getCurrentInstance } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus'
import cTable from '@/components/CustomTable'
const { proxy } = getCurrentInstance()

const loading = ref(false)

const activityListDataUrl = proxy.$api.merchant.activity.list
const requestedActivityDataUrl = proxy.$api.merchant.activity.requestedRegister;
const merInfo = ref(null)
/**
 * 按钮
 */
const activity = ref('activity_list')
const showTable = ref('activity_list')
const change = (value) => {
    showTable.value = value
}


/**
 * 商户活动费率配置
 */
const setActivityRates = (row => {
    loading.value = true
    const params = {
        activity_id: row.activity_id,
        channel_id: row.payment_channel_id
    }

    proxy.$api.merchant.activity.setActivityRates(merInfo.value.id, params).then(r => {
        if (r.status == 200) {
            ElMessage({ message: '配置成功', type: 'success' })
        } else {
            ElMessage({ message: r.error.message, type: 'error' })
        }
        loading.value = false
    })
})


/**
 * 优惠活动状态查询
 */

const merchantActivityStatusQuery = (row => {
    loading.value = true
    const params = {
        activity_id: row.activity_id,
        channel_id: row.payment_channel_id
    }
    proxy.$api.merchant.activity.requestedActivityStatus(merInfo.value.id, params).then(r => {
        if (r.status == 200) {
            ElMessage({ message: r.data.registerStatusDesc, type: 'success' })
            if (r.data.isSyncData) {
                row.preferred_rate_config = r.data.preferred_rate_config
                row.preferred_mode = r.data.preferred_mode
                row.preferred_config_mode = r.data.preferred_config_mode
                row.preferential_limit = r.data.preferential_limit
                row.start = r.data.start
                row.end = r.data.end
                row.status = r.data.status
            }
            if (r.data.justStatus) {
                row.status = r.data.status
            }
        } else {
            ElMessage({ message: r.error.message, type: 'error' })
        }
        loading.value = false
    })
})

/**
 * 活动报名
 * @param {*} row 
 */
const activityRegister = (row) => {
    const params = {
        channel_id: row.payment_channel_id,
        activity_id: row.activity_id,
        manager_check: row.manager_check
    }
    proxy.$api.merchant.activity.register(merInfo.value.id, params).then((r) => {
        if (r.status == 200) {
            ElMessage({ message: '报名成功', type: 'success' })
        } else {
            ElMessage({ message: r.error.message, type: 'error' })
        }
    })
}

/**
 * 活动详情
 */
const description = (row) => {
    ElMessageBox.alert(
        row.details,
        row.name,
        { dangerouslyUseHTMLString: true }
    )
}

/**
 * 显示dialog弹窗
 */
const merchantName = ref('')
const isShow = ref(false)
const open = (merchantInfo) => {
    isShow.value = true
    merchantName.value = merchantInfo.merchant_name
    merInfo.value = merchantInfo

    const requestedParams = {
        pageSize: 15,
        page: 1,
        current: 1
    }

    proxy.$api.merchant.activity.requestedRegister(merInfo.value.id, requestedParams).then((r) => {
        proxy.$refs.requested_activity.setTableDataList(r);
    })
}

defineExpose({ open })
</script>

<style lang="less" scoped>
.list-box {
    margin-top: 30px;
}

.text-overflow {
    width: 50%;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}
</style>